<!--
 * @Author: wh
 * @Date: 2020-10-14 09:48:39
 * @LastEditTime: 2020-10-14 10:34:39
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \Web\JT.Web\src\views\productionManagement\stockCheck\index.vue
-->
<template>
    <div class="stockCheck"></div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
        };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {},
    methods: {
    },
};
</script>
<style lang="stylus" scoped>
.wrapper{}
</style>